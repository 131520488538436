export const Autocounter = () => import('../..\\components\\Autocounter.vue' /* webpackChunkName: "components/autocounter" */).then(c => wrapFunctional(c.default || c))
export const CookieBanner = () => import('../..\\components\\CookieBanner.vue' /* webpackChunkName: "components/cookie-banner" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../..\\components\\Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FooterContactUs = () => import('../..\\components\\FooterContactUs.vue' /* webpackChunkName: "components/footer-contact-us" */).then(c => wrapFunctional(c.default || c))
export const GoogleMapLine = () => import('../..\\components\\GoogleMapLine.vue' /* webpackChunkName: "components/google-map-line" */).then(c => wrapFunctional(c.default || c))
export const GoogleMapLoader = () => import('../..\\components\\GoogleMapLoader.vue' /* webpackChunkName: "components/google-map-loader" */).then(c => wrapFunctional(c.default || c))
export const GoogleMapMarker = () => import('../..\\components\\GoogleMapMarker.vue' /* webpackChunkName: "components/google-map-marker" */).then(c => wrapFunctional(c.default || c))
export const LoaderSpinner = () => import('../..\\components\\LoaderSpinner.vue' /* webpackChunkName: "components/loader-spinner" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../..\\components\\Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const Navbar = () => import('../..\\components\\Navbar.vue' /* webpackChunkName: "components/navbar" */).then(c => wrapFunctional(c.default || c))
export const Notice = () => import('../..\\components\\Notice.vue' /* webpackChunkName: "components/notice" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethod = () => import('../..\\components\\PaymentMethod.vue' /* webpackChunkName: "components/payment-method" */).then(c => wrapFunctional(c.default || c))
export const TopContent = () => import('../..\\components\\TopContent.vue' /* webpackChunkName: "components/top-content" */).then(c => wrapFunctional(c.default || c))
export const TravelMap = () => import('../..\\components\\TravelMap.vue' /* webpackChunkName: "components/travel-map" */).then(c => wrapFunctional(c.default || c))
export const AuthConfirmLogin = () => import('../..\\components\\auth\\ConfirmLogin.vue' /* webpackChunkName: "components/auth-confirm-login" */).then(c => wrapFunctional(c.default || c))
export const AuthForgotPassword = () => import('../..\\components\\auth\\ForgotPassword.vue' /* webpackChunkName: "components/auth-forgot-password" */).then(c => wrapFunctional(c.default || c))
export const AuthLogin = () => import('../..\\components\\auth\\Login.vue' /* webpackChunkName: "components/auth-login" */).then(c => wrapFunctional(c.default || c))
export const EventDetails = () => import('../..\\components\\EventShipping\\EventDetails.vue' /* webpackChunkName: "components/event-details" */).then(c => wrapFunctional(c.default || c))
export const FormCollapsibleOption = () => import('../..\\components\\form\\CollapsibleOption.vue' /* webpackChunkName: "components/form-collapsible-option" */).then(c => wrapFunctional(c.default || c))
export const FormForgotPassword = () => import('../..\\components\\form\\ForgotPassword.vue' /* webpackChunkName: "components/form-forgot-password" */).then(c => wrapFunctional(c.default || c))
export const FormGuestLogin = () => import('../..\\components\\form\\GuestLogin.vue' /* webpackChunkName: "components/form-guest-login" */).then(c => wrapFunctional(c.default || c))
export const FormLargeSelect = () => import('../..\\components\\form\\LargeSelect.vue' /* webpackChunkName: "components/form-large-select" */).then(c => wrapFunctional(c.default || c))
export const FormLogin = () => import('../..\\components\\form\\Login.vue' /* webpackChunkName: "components/form-login" */).then(c => wrapFunctional(c.default || c))
export const FormRegister = () => import('../..\\components\\form\\Register.vue' /* webpackChunkName: "components/form-register" */).then(c => wrapFunctional(c.default || c))
export const FormResetPassword = () => import('../..\\components\\form\\ResetPassword.vue' /* webpackChunkName: "components/form-reset-password" */).then(c => wrapFunctional(c.default || c))
export const FormSingleInput = () => import('../..\\components\\form\\SingleInput.vue' /* webpackChunkName: "components/form-single-input" */).then(c => wrapFunctional(c.default || c))
export const FormSingleSelect = () => import('../..\\components\\form\\SingleSelect.vue' /* webpackChunkName: "components/form-single-select" */).then(c => wrapFunctional(c.default || c))
export const FormSmallRoundedSelect = () => import('../..\\components\\form\\SmallRoundedSelect.vue' /* webpackChunkName: "components/form-small-rounded-select" */).then(c => wrapFunctional(c.default || c))
export const MenuNavMenu = () => import('../..\\components\\menu\\NavMenu.vue' /* webpackChunkName: "components/menu-nav-menu" */).then(c => wrapFunctional(c.default || c))
export const MenuUserMenu = () => import('../..\\components\\menu\\UserMenu.vue' /* webpackChunkName: "components/menu-user-menu" */).then(c => wrapFunctional(c.default || c))
export const ShipAddressDropdown = () => import('../..\\components\\ship\\AddressDropdown.vue' /* webpackChunkName: "components/ship-address-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ShipAddressInput = () => import('../..\\components\\ship\\AddressInput.vue' /* webpackChunkName: "components/ship-address-input" */).then(c => wrapFunctional(c.default || c))
export const ShipAddressInputSplit = () => import('../..\\components\\ship\\AddressInputSplit.vue' /* webpackChunkName: "components/ship-address-input-split" */).then(c => wrapFunctional(c.default || c))
export const ShipBoxCaseForm = () => import('../..\\components\\ship\\BoxCaseForm.vue' /* webpackChunkName: "components/ship-box-case-form" */).then(c => wrapFunctional(c.default || c))
export const ShipOrderSummary = () => import('../..\\components\\ship\\orderSummary.vue' /* webpackChunkName: "components/ship-order-summary" */).then(c => wrapFunctional(c.default || c))
export const ShipRecentSearch = () => import('../..\\components\\ship\\RecentSearch.vue' /* webpackChunkName: "components/ship-recent-search" */).then(c => wrapFunctional(c.default || c))
export const ShipSteps = () => import('../..\\components\\ship\\ShipSteps.vue' /* webpackChunkName: "components/ship-steps" */).then(c => wrapFunctional(c.default || c))
export const ShipStageForm = () => import('../..\\components\\ship\\StageForm.vue' /* webpackChunkName: "components/ship-stage-form" */).then(c => wrapFunctional(c.default || c))
export const ShipStepsContents = () => import('../..\\components\\ship\\steps\\Contents.vue' /* webpackChunkName: "components/ship-steps-contents" */).then(c => wrapFunctional(c.default || c))
export const ShipStepsCosts = () => import('../..\\components\\ship\\steps\\Costs.vue' /* webpackChunkName: "components/ship-steps-costs" */).then(c => wrapFunctional(c.default || c))
export const ShipStepsDetails = () => import('../..\\components\\ship\\steps\\Details.vue' /* webpackChunkName: "components/ship-steps-details" */).then(c => wrapFunctional(c.default || c))
export const ShipStepsPayment = () => import('../..\\components\\ship\\steps\\Payment.vue' /* webpackChunkName: "components/ship-steps-payment" */).then(c => wrapFunctional(c.default || c))
export const ShipStepsSearch = () => import('../..\\components\\ship\\steps\\Search.vue' /* webpackChunkName: "components/ship-steps-search" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
