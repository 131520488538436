
    import Vue from 'vue';
    import {mapActions, mapMutations} from "vuex";
    import Notice from "~/entities/Notice";

    export default Vue.extend({
      auth: 'guest',
        data() {
          return {
            email: '' as string,
            password: '' as string,
            successLogin: null as null | boolean,
            actionNotice: null as Notice | null,
            submitted: false as boolean
          }
        },
        methods: {
            closeMenu(){
              this.$emit('closeMenu', 0);
            },
            ...mapMutations({
              setLoading: 'setLoading',
            }),
            ...mapActions({
              loginUser: 'user/loginUser',
            }),
            onSwitchForm() {
              this.$emit('switchForms', 1);
            },
            isValidEmail(email){
              let validEmail = this.$refs.authEmail as HTMLInputElement
              return validEmail ? validEmail.checkValidity() : false;
            },
            resetVal() {
              this.successLogin = null;
            },
            submitLogin(){
              if( this.email && this.password ){
                this.successLogin = null;
                this.loginUser({email: this.email, password: this.password}).then(res => { 
                  if(!res){
                    this.successLogin = res;
                    this.submitted = true;
                    this.actionNotice = new Notice({ expletive:'Error', message:'You have entered an invalid username or password', type:'alert-danger', dismissable: false });
                  }
                });
              }else{
                this.successLogin = false;
                this.submitted = true;
              }
            }
        },
    });
