import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _46bdbb54 = () => interopDefault(import('..\\pages\\aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _48802cf9 = () => interopDefault(import('..\\pages\\accessibility.vue' /* webpackChunkName: "pages/accessibility" */))
const _1313d7d6 = () => interopDefault(import('..\\pages\\advocacy.vue' /* webpackChunkName: "pages/advocacy" */))
const _34a24a10 = () => interopDefault(import('..\\pages\\affiliate.vue' /* webpackChunkName: "pages/affiliate" */))
const _0ea5582d = () => interopDefault(import('..\\pages\\ambassadors.vue' /* webpackChunkName: "pages/ambassadors" */))
const _dbad0a8c = () => interopDefault(import('..\\pages\\assembly-guide.vue' /* webpackChunkName: "pages/assembly-guide" */))
const _0a61007a = () => interopDefault(import('..\\pages\\bicycleshops.vue' /* webpackChunkName: "pages/bicycleshops" */))
const _b58d0fc0 = () => interopDefault(import('..\\pages\\branding.vue' /* webpackChunkName: "pages/branding" */))
const _d5795678 = () => interopDefault(import('..\\pages\\buckupforbikes.vue' /* webpackChunkName: "pages/buckupforbikes" */))
const _ef960c60 = () => interopDefault(import('..\\pages\\changePassword.vue' /* webpackChunkName: "pages/changePassword" */))
const _92bc23c8 = () => interopDefault(import('..\\pages\\claims.vue' /* webpackChunkName: "pages/claims" */))
const _4836d240 = () => interopDefault(import('..\\pages\\claimsform.vue' /* webpackChunkName: "pages/claimsform" */))
const _78833dda = () => interopDefault(import('..\\pages\\confirmation.vue' /* webpackChunkName: "pages/confirmation" */))
const _6c127560 = () => interopDefault(import('..\\pages\\contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _429aa0ce = () => interopDefault(import('..\\pages\\dimensionsandrates.vue' /* webpackChunkName: "pages/dimensionsandrates" */))
const _a78670c6 = () => interopDefault(import('..\\pages\\e-bike-shipping.vue' /* webpackChunkName: "pages/e-bike-shipping" */))
const _092c37e6 = () => interopDefault(import('..\\pages\\event-shipping.vue' /* webpackChunkName: "pages/event-shipping" */))
const _ce49967a = () => interopDefault(import('..\\pages\\forgotPassword.vue' /* webpackChunkName: "pages/forgotPassword" */))
const _1a39feb3 = () => interopDefault(import('..\\pages\\guarantee.vue' /* webpackChunkName: "pages/guarantee" */))
const _78cb877f = () => interopDefault(import('..\\pages\\history.vue' /* webpackChunkName: "pages/history" */))
const _2bdcd1e4 = () => interopDefault(import('..\\pages\\international_bicycle_shipping.vue' /* webpackChunkName: "pages/international_bicycle_shipping" */))
const _cf271b0a = () => interopDefault(import('..\\pages\\jobs.vue' /* webpackChunkName: "pages/jobs" */))
const _cf02ced8 = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages/login" */))
const _24605ebe = () => interopDefault(import('..\\pages\\pack.vue' /* webpackChunkName: "pages/pack" */))
const _902d93fa = () => interopDefault(import('..\\pages\\packing-guide.vue' /* webpackChunkName: "pages/packing-guide" */))
const _e6358578 = () => interopDefault(import('..\\pages\\pre-confirmation.vue' /* webpackChunkName: "pages/pre-confirmation" */))
const _1b75d865 = () => interopDefault(import('..\\pages\\privacypolicy.vue' /* webpackChunkName: "pages/privacypolicy" */))
const _6e679a14 = () => interopDefault(import('..\\pages\\profile.vue' /* webpackChunkName: "pages/profile" */))
const _926bf41e = () => interopDefault(import('..\\pages\\QBPtopshop.vue' /* webpackChunkName: "pages/QBPtopshop" */))
const _80370c62 = () => interopDefault(import('..\\pages\\refundpolicy.vue' /* webpackChunkName: "pages/refundpolicy" */))
const _9b227070 = () => interopDefault(import('..\\pages\\register.vue' /* webpackChunkName: "pages/register" */))
const _9a67bb86 = () => interopDefault(import('..\\pages\\registerthankyou.vue' /* webpackChunkName: "pages/registerthankyou" */))
const _101cf9be = () => interopDefault(import('..\\pages\\reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _4e59e53e = () => interopDefault(import('..\\pages\\ship.vue' /* webpackChunkName: "pages/ship" */))
const _b3a50576 = () => interopDefault(import('..\\pages\\shippingpolicy.vue' /* webpackChunkName: "pages/shippingpolicy" */))
const _6aced88b = () => interopDefault(import('..\\pages\\staff.vue' /* webpackChunkName: "pages/staff" */))
const _f812a1e8 = () => interopDefault(import('..\\pages\\sustainability.vue' /* webpackChunkName: "pages/sustainability" */))
const _c3032e88 = () => interopDefault(import('..\\pages\\termsofservice.vue' /* webpackChunkName: "pages/termsofservice" */))
const _2ee07c68 = () => interopDefault(import('..\\pages\\timeout.vue' /* webpackChunkName: "pages/timeout" */))
const _2a78d976 = () => interopDefault(import('..\\pages\\track.vue' /* webpackChunkName: "pages/track" */))
const _b022e448 = () => interopDefault(import('..\\pages\\tracking.vue' /* webpackChunkName: "pages/tracking" */))
const _520ec613 = () => interopDefault(import('..\\pages\\trustpilot-reviews.vue' /* webpackChunkName: "pages/trustpilot-reviews" */))
const _09fe65cc = () => interopDefault(import('..\\pages\\workwithus.vue' /* webpackChunkName: "pages/workwithus" */))
const _e2bacdf6 = () => interopDefault(import('..\\pages\\youraccount.vue' /* webpackChunkName: "pages/youraccount" */))
const _54c7d00c = () => interopDefault(import('..\\pages\\youraddresses.vue' /* webpackChunkName: "pages/youraddresses" */))
const _23a85c91 = () => interopDefault(import('..\\pages\\yourboxes.vue' /* webpackChunkName: "pages/yourboxes" */))
const _5a4a883d = () => interopDefault(import('..\\pages\\yourorders.vue' /* webpackChunkName: "pages/yourorders" */))
const _2aa0fef6 = () => interopDefault(import('..\\pages\\yourpayments.vue' /* webpackChunkName: "pages/yourpayments" */))
const _2d0a5d2e = () => interopDefault(import('..\\pages\\yoursearches.vue' /* webpackChunkName: "pages/yoursearches" */))
const _47e7e419 = () => interopDefault(import('..\\pages\\events\\_event.vue' /* webpackChunkName: "pages/events/_event" */))
const _5bd6d52a = () => interopDefault(import('..\\pages\\partners\\_event.vue' /* webpackChunkName: "pages/partners/_event" */))
const _7b14e912 = () => interopDefault(import('..\\pages\\event2\\_event\\_id.vue' /* webpackChunkName: "pages/event2/_event/_id" */))
const _62521506 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _e9805c00 = () => interopDefault(import('..\\pages\\_event.vue' /* webpackChunkName: "pages/_event" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _46bdbb54,
    name: "aboutus"
  }, {
    path: "/accessibility",
    component: _48802cf9,
    name: "accessibility"
  }, {
    path: "/advocacy",
    component: _1313d7d6,
    name: "advocacy"
  }, {
    path: "/affiliate",
    component: _34a24a10,
    name: "affiliate"
  }, {
    path: "/ambassadors",
    component: _0ea5582d,
    name: "ambassadors"
  }, {
    path: "/assembly-guide",
    component: _dbad0a8c,
    name: "assembly-guide"
  }, {
    path: "/bicycleshops",
    component: _0a61007a,
    name: "bicycleshops"
  }, {
    path: "/branding",
    component: _b58d0fc0,
    name: "branding"
  }, {
    path: "/buckupforbikes",
    component: _d5795678,
    name: "buckupforbikes"
  }, {
    path: "/changePassword",
    component: _ef960c60,
    name: "changePassword"
  }, {
    path: "/claims",
    component: _92bc23c8,
    name: "claims"
  }, {
    path: "/claimsform",
    component: _4836d240,
    name: "claimsform"
  }, {
    path: "/confirmation",
    component: _78833dda,
    name: "confirmation"
  }, {
    path: "/contact-us",
    component: _6c127560,
    name: "contact-us"
  }, {
    path: "/dimensionsandrates",
    component: _429aa0ce,
    name: "dimensionsandrates"
  }, {
    path: "/e-bike-shipping",
    component: _a78670c6,
    name: "e-bike-shipping"
  }, {
    path: "/event-shipping",
    component: _092c37e6,
    name: "event-shipping"
  }, {
    path: "/forgotPassword",
    component: _ce49967a,
    name: "forgotPassword"
  }, {
    path: "/guarantee",
    component: _1a39feb3,
    name: "guarantee"
  }, {
    path: "/history",
    component: _78cb877f,
    name: "history"
  }, {
    path: "/international_bicycle_shipping",
    component: _2bdcd1e4,
    name: "international_bicycle_shipping"
  }, {
    path: "/jobs",
    component: _cf271b0a,
    name: "jobs"
  }, {
    path: "/login",
    component: _cf02ced8,
    name: "login"
  }, {
    path: "/pack",
    component: _24605ebe,
    name: "pack"
  }, {
    path: "/packing-guide",
    component: _902d93fa,
    name: "packing-guide"
  }, {
    path: "/pre-confirmation",
    component: _e6358578,
    name: "pre-confirmation"
  }, {
    path: "/privacypolicy",
    component: _1b75d865,
    name: "privacypolicy"
  }, {
    path: "/profile",
    component: _6e679a14,
    name: "profile"
  }, {
    path: "/QBPtopshop",
    component: _926bf41e,
    name: "QBPtopshop"
  }, {
    path: "/refundpolicy",
    component: _80370c62,
    name: "refundpolicy"
  }, {
    path: "/register",
    component: _9b227070,
    name: "register"
  }, {
    path: "/registerthankyou",
    component: _9a67bb86,
    name: "registerthankyou"
  }, {
    path: "/reset-password",
    component: _101cf9be,
    name: "reset-password"
  }, {
    path: "/ship",
    component: _4e59e53e,
    name: "ship"
  }, {
    path: "/shippingpolicy",
    component: _b3a50576,
    name: "shippingpolicy"
  }, {
    path: "/staff",
    component: _6aced88b,
    name: "staff"
  }, {
    path: "/sustainability",
    component: _f812a1e8,
    name: "sustainability"
  }, {
    path: "/termsofservice",
    component: _c3032e88,
    name: "termsofservice"
  }, {
    path: "/timeout",
    component: _2ee07c68,
    name: "timeout"
  }, {
    path: "/track",
    component: _2a78d976,
    name: "track"
  }, {
    path: "/tracking",
    component: _b022e448,
    name: "tracking"
  }, {
    path: "/trustpilot-reviews",
    component: _520ec613,
    name: "trustpilot-reviews"
  }, {
    path: "/workwithus",
    component: _09fe65cc,
    name: "workwithus"
  }, {
    path: "/youraccount",
    component: _e2bacdf6,
    name: "youraccount"
  }, {
    path: "/youraddresses",
    component: _54c7d00c,
    name: "youraddresses"
  }, {
    path: "/yourboxes",
    component: _23a85c91,
    name: "yourboxes"
  }, {
    path: "/yourorders",
    component: _5a4a883d,
    name: "yourorders"
  }, {
    path: "/yourpayments",
    component: _2aa0fef6,
    name: "yourpayments"
  }, {
    path: "/yoursearches",
    component: _2d0a5d2e,
    name: "yoursearches"
  }, {
    path: "/events/:event?",
    component: _47e7e419,
    name: "events-event"
  }, {
    path: "/partners/:event?",
    component: _5bd6d52a,
    name: "partners-event"
  }, {
    path: "/event2/:event?/:id?",
    component: _7b14e912,
    name: "event2-event-id"
  }, {
    path: "/",
    component: _62521506,
    name: "index"
  }, {
    path: "/:event",
    component: _e9805c00,
    name: "event"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
