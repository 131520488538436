import Vue from 'vue';
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import * as Sentry from "@sentry/vue";
import { Auth as NuxtAuth } from "@nuxtjs/auth-next"
import {RootState} from '~/store/index'
import User from "~/entities/User";
import * as StoreHelper from '~/entities/StoreHelper';

/* This is used to set the nuxt auth-module user type */
declare module "vue/types/vue" {
  interface Vue {
    // @ts-ignore
    $auth: NuxtAuth & {
      user?: User
    }
  }
}


export const state = () => ({
  // values
  paymentDetails: {} as any,
  buckForBikes: null as any, // lets fix this, make it a donation object for consistency
  PO: null as any,

  // discounts
  discount: null as unknown as any,
  promoDiscount: null as unknown as any,
  customerBalance: null as unknown as any,

  // totals
  subTotal: 0 as number,
  subTotalBeforeDiscount: 0 as number,
  discountTotal: 0 as number,
  discountAmount: 0 as number,
  promoDiscountAmount: 0 as number,
  customerBalanceApplied: 0 as number,

  // order details
  orderDetails: null as unknown as any,
});

export type PaymentState = ReturnType<typeof state>

export const getters: GetterTree<PaymentState, RootState> = {
  // values
  paymentDetails : (state) => state.paymentDetails,
  buckForBikes : (state) => state.buckForBikes,
  PO : (state) => state.PO,

  // discounts
  discount : (state) => state.discount,
  promoDiscount: (state) => state.promoDiscount,
  customerBalance: (state) => state.customerBalance,

  // totals
  subTotal : (state) => state.subTotal,
  subTotalBeforeDiscount : (state) => state.subTotalBeforeDiscount,
  discountTotal : (state) => state.discountTotal,
  discountAmount : (state) => state.discountAmount,
  promoDiscountAmount: (state) => state.promoDiscountAmount,
  customerBalanceApplied: (state) => state.customerBalanceApplied,

  // order details
  orderDetails : (state) => state.orderDetails,
}

export const mutations: MutationTree<PaymentState> = {
  // values
  setPaymentDetails(state, value){
    state.paymentDetails = value;
  },
  setBuckForBikes : (state, value) => {
    state.buckForBikes = value;
  },
  setPO: (state, value : any) =>{
    state.PO = value
  },

  // discounts
  setDiscount: (state, value : any) => {
    if(value){
      if(value.amountOff){
        value.discountType = 2;
        value.discount = value.amountOff/100;
      }else{
        value.discountType = 1;
        value.discount = value.percentOff / 100;
      }
    }
    state.discount = value;
  },
  unsetDiscount: (state) => {
    state.discount = null;
    state.discountAmount = 0;
  },
  setPromoDiscount: (state, value : any) => {
    if(value){
      const promo = value.promotionCode;
      const coupon = promo.coupon;
      if(promo){
        coupon.id = promo.id;
      }
      if(coupon){
        if(coupon.amountOff){
          coupon.discountType = 2;
          coupon.discount = coupon.amountOff/100;
        }else{
          coupon.discountType = 1;
          coupon.discount = coupon.percentOff / 100;
        }
        state.promoDiscount = coupon;
      }
    }
  },
  unsetPromoDiscount: (state) => {
    state.promoDiscount = null;
    state.promoDiscountAmount = 0;
  },
  setCustomerBalance: (state, value : any) => {
    state.customerBalance = value;
  },
  unsetCustomerBalance: (state) => {
    state.customerBalance = null;
    state.customerBalanceApplied = 0;
  },

  // totals
  setSubTotal : (state, value) => {
    Vue.set(state, 'subTotal', value);
    // state.subTotal = value;
  },
  setSubTotalBeforeDiscount: (state, value : any) => {
    state.subTotalBeforeDiscount = value;
  },
  setDiscountTotal: (state, value : any) => {
    state.discountTotal = value;
  },
  setDiscountAmount: (state, value : any) => {
    state.discountAmount = value;
  },
  setPromoDiscountAmount: (state, value : any) => {
    state.promoDiscountAmount = value;
  },
  setCustomerBalanceApplied: (state, value) => {
    state.customerBalanceApplied = value;
  },

  // order details
  setOrderDetails: (state, value: any) => {
    state.orderDetails = value;
  },

  // flusher
  flush: (state, caller: string) => {
    if(caller !== 'bicycleshops'){
      // this should go here too ?
      state.discount = null; // this needs to be called in a different flush method, one for payment.
      state.discountAmount = 0; // this needs to be called in a different flush method, one for payment.
      state.promoDiscount = null; // this needs to be called in a different flush method, one for payment.
      state.promoDiscountAmount = 0; // this needs to be called in a different flush method, one for payment.
      state.customerBalance = null; // this needs to be called in a different flush method, one for payment.
      state.customerBalanceApplied = 0; // this needs to be called in a different flush method, one for payment.
      state.PO = null; // this needs to be called in a different flush method, one for payment.
      // probably yes, this should go here too
    }
    state.paymentDetails = {}; // this needs to be called in a different flush method, one for payment.

    state.subTotal = 0;// this needs to be called in a different flush method, one for payment.

    state.buckForBikes = null; // this needs to be called in a different flush method, one for payment.

    if(caller === 'ship'){// this needs to be called in a different flush method, one for payment.
      state.discount = null;
      state.discountAmount = 0;
      state.promoDiscount = null;
      state.promoDiscountAmount = 0;
      state.customerBalance = null;
      state.customerBalanceApplied = 0;
      state.PO = null;
    }
  },
  clearOrderDetailsOnDestroy: (state, value: any) => {
    // clear the order details, so if a user comes back to the page, they don't see the previous order details and the message of warning comes back
    state.orderDetails = null;
  },
}

export const actions: ActionTree<PaymentState, RootState> = {
  calculateSubTotal ({rootGetters, rootState, dispatch, commit }) {
    let total = 0;
    let subtotal = 0;
    let discountTotal = 0;
    let discountAmount = 0;
    let promoDiscountAmount = 0;
    const fees = this.getters['ship/fees'];
    const discount = this.getters['payment/discount'];
    const promoDiscount = this.getters['payment/promoDiscount'];
    const premiumProtection = this.getters['ship/premiumProtection']?.cost;
    try{
      if(this.getters['ship/rates']?.[0]){
        for(let step = 0; step < this.getters['ship/rates'][0].length; step++){
          subtotal += this.getters['ship/rates'][0][step].pickupFee ? this.getters['ship/rates'][0][step].pickupFee : 0;
          for(let rateStep = 0; rateStep < this.getters['ship/rates'][0][step].rates.length; rateStep++){
            if(this.getters['ship/rates'][0][step].rates[rateStep].selected){
              subtotal += this.getters['ship/rates'][0][step].rates[rateStep].rate; // base amount
              const landedCost = fees?.[step].landedCost;
              const fee:any = landedCost?.length ? landedCost.filter(f => f.stage === step && f.result === rateStep) : 0;
              subtotal += fee?.length ? (fee[0].clearanceFee ? fee[0].clearanceFee : 0) : 0;
              const residential:any = fees?.[step]?.residentialFee ? fees[step].residentialFee : 0;
              subtotal += residential;
            }
          }
        }
        subtotal += ( premiumProtection ? premiumProtection : 0 ) * this.getters['ship/rates'][0].length; // safe valved premium protection to multiply by 0 in case it is not set, so it doesn't add anything to the total
        total = subtotal; // to be used for the less than $1 limit check.
               
        // user discount
        if(discount?.discountType === 2){ // fixed
          discountAmount += discount.discount;
        }else if(discount?.discountType < 2){ // percent
          discountAmount += (((discount.discount * 100) * subtotal) / 100);
        }

        // promo discount
        if(promoDiscount?.discountType === 2){ // fixed
          promoDiscountAmount += promoDiscount.discount;
        }else if(promoDiscount?.discountType < 2){ // percent
          promoDiscountAmount += (((promoDiscount.discount * 100) * subtotal) / 100);
        }

        const buckForBikes = this.getters['payment/buckForBikes'];
        commit('payment/setSubTotalBeforeDiscount', subtotal + buckForBikes, { root: true });

        // customer balance
        if(this.getters['payment/customerBalanceApplied']){
          subtotal -= this.getters['payment/customerBalanceApplied'];
        }
        // calculate total
        // state.discountAmount = discountAmount;
        commit('payment/setDiscountAmount', discountAmount, { root: true });

        // state.promoDiscountAmount = promoDiscountAmount;
        commit('payment/setPromoDiscountAmount', promoDiscountAmount, { root: true });

        discountTotal = discountAmount + promoDiscountAmount;
        // state.discountTotal = discountTotal;
        commit('payment/setDiscountTotal', discountTotal, { root: true });

        subtotal = subtotal - discountTotal > 0 ? subtotal - discountTotal : 0;
        subtotal += buckForBikes;
      }
      commit('setSubTotal', subtotal);
    }catch (e){
      console.log(e);
      commit('setSubTotal', 0);
    }
  },

  // stripe
  async requestClientSecret({rootGetters, rootState, dispatch, commit }, data){
    try{
      return await this.$axios.$post(`/api/Stripe/client-secret`, data);
    }catch (e){
      return false;
    }
  },
  async requestStripeUpdate({rootGetters, rootState, dispatch, commit }, data){
    try{
      return await this.$axios.$post(`/api/Stripe/update`, data);
    }catch (e){
      return false;
    }
  },

  // Stripe && orders
  async createOrder({rootGetters, rootState, dispatch, commit, getters }, data){
    try{
      data.rates = this.getters['ship/rates'];
      data.selectedRateSpeed = this.getters['ship/selectedRateSpeed'];
      data.selectedRateSpeedIndex = this.getters['ship/selectedRateSpeedIndex'];
      data.premiumProtection = this.getters['ship/premiumProtection'];
      data.fees = this.getters['ship/fees'];
      const requestBodyObject = StoreHelper.default.getCreateOrderStripeBodyObject(getters, data);
      const response = await this.$axios.$post(`/api/Order/create-order`, requestBodyObject).catch(function (error) {
        if(error){
          // no need to braedcrumb the create because getCreateOrderStripeBodyObject already does it.
          Sentry.captureEvent({
            message: 'api/Order/create-order failed: '+JSON.stringify(error.response && error.response.data ? error.response.data : ''),
            level: 'error',
          });
        }
        return false;
      });
      if(response)
        return response;
      return false;
    }catch (e) {
      console.log('savePaidOrderError: ', e);
      return false;
    }
  },
  async confirmOrder({rootGetters, rootState, dispatch, commit, getters }, data){
    try{
      const requestBodyObject = data;
      try{
        Sentry.addBreadcrumb({
          message: 'Threshold: confirmOrder request.',
          data: {confirmOrderRequestBodyObject:requestBodyObject},
          category: 'confirmOrderRequestBodyObject',
        });
      }catch (e) {
        console.log(e);
      }
      const response = await this.$axios.$post(`/api/Order/confirm-order`, requestBodyObject).catch(function (error) {
        if(error){
          Sentry.captureEvent({
            message: 'api/Order/confirm-order failed: '+JSON.stringify(error.response && error.response.data ? error.response.data : ''),
            level: 'error',
          });
        }
        return false;
      });
      if(response){
        try{
          let hadFailedAVS = false;
          const details = this.getters['ship/details'];
          if(details?.constructor === Array && details?.length){
            for(let step = 0; step < details.length; step++){
              const detailFrom = details[step]?.from;
              const detailTo = details[step]?.to;
              if(detailFrom && (!detailFrom.hasOwnProperty('passedAvs') || !detailFrom.passedAvs)
                || detailTo && (!detailTo.hasOwnProperty('passedAvs') && !detailTo.passedAvs)){
                hadFailedAVS = true;
                break;
              }
            }
          }
          if(hadFailedAVS){
            Sentry.captureEvent({
              message: 'Order #'+ (requestBodyObject.OrderId) +' confirmed with addresses that failed AVS',
              level: 'warning',
            });
          }
        }catch (e) {
          console.error(e);
        }
        return response;
      }
      return false;
    }catch (e) {
      console.log('savePaidOrderError: ', e);
      return false;
    }
  },
  async savePaidOrder({rootGetters, rootState, dispatch, commit, getters }, data){
    try{
      data.rates = this.getters['ship/rates'];
      data.selectedRateSpeed = this.getters['ship/selectedRateSpeed'];
      data.selectedRateSpeedIndex = this.getters['ship/selectedRateSpeedIndex'];
      data.premiumProtection = this.getters['ship/premiumProtection'];
      data.fees = this.getters['ship/fees'];
      const requestBodyObject = StoreHelper.default.getCreateOrderStripeBodyObject(getters, data);
      const response = await this.$axios.$post(`/api/Order/payment`, requestBodyObject).catch(function (error) {
        if(error.response.status == 403){
          return 403;
        }
        return false;
      });
      if(response == 403){
        return 403;
      }
      if(response)
        return response;
      return false;
    }catch (e) {
      console.log('savePaidOrderError: ', e);
      return false;
    }
  },
  async requestRefund({rootGetters, rootState, dispatch, commit, getters }, data){
    try{
      const response = await (this as any).$axios.$get(`/api/Stripe/refund/`+(data?data:'')).catch(function (error) {
        return false;
      });
      return !!response;
    }catch (e){
      return false;
    }
  },

  // discount
  async getDiscount({rootGetters, rootState, dispatch, commit, getters }){
    try{
      if(this.getters['payment/discount'] === null){
        commit('setDiscount', false); // this is to prevent calls to get discount while the previous call hasn't completed
        const response = await (this as any).$axios.$get(`/api/Stripe/get-customer-discount`).catch(function (error) {
          commit('setDiscount', false); // marks the discount as invalid, yet requested
          return false;
        });
        if(response){
          commit('setDiscount', response); // discount is there
          return true;
        }
      }
    }catch (e){
      console.log(e);
      commit('unsetDiscount');// reset all
      return false;
    }
  },

  // promo code
  async validatePromotion({rootGetters, rootState, dispatch, commit, getters}, data){
    try{
      commit('unsetPromoDiscount');
      let params = '';
      if(data.couponCode ){
        params = data.couponCode;
      }
      if(data.orderTotal){
        params += '?orderTotal='+data.orderTotal;
      }
      const response = await this.$axios.$get(`/api/Stripe/validate-promotion/`+params).catch(function (error) {
        return error.response.data;
      });
      if(response && response.valid){
        commit('setPromoDiscount', response); // discount is there
      }
      return response;
    }catch (e){
      return false;
    }
  },

  // payment methods
  async getPaymentMethods({rootGetters, rootState, dispatch, commit, getters}){
    try{
      const response = await (this as any).$axios.$get(`/api/Stripe/get-payment-methods`).catch(function (error) {
        console.log(error);
        return [];
      });
      return response;
    }catch (e) {
      return [];
    }
  },
  async removePaymentMethod({rootGetters, rootState, dispatch, commit, getters}, data){
    try{
      const deleted = await (this as any).$axios.$delete(`/api/Stripe/detach-payment-method/`+(data ? data : ''), {withCredentials: true});
      return true;
    }catch (e) {
      return false;
    }
  },

  // customer balance
  async getCustomerBalance({rootGetters, rootState, dispatch, commit, getters}){
    try{
      if(this.getters['payment/customerBalance'] === null){
        commit('setCustomerBalance', 0); // this is to prevent calls while the previous call hasn't completed
        const response = await (this as any).$axios.$get(`/api/Stripe/get-customer-balance`).catch(function (error) {
          commit('setCustomerBalance', 0); // marks the balance as invalid, yet requested
          return false;
        });
        if(response){
          commit('setCustomerBalance', Number(response) * -1); // CB is there // add *-1 in BIK-732
          return true;
        }
      }
    }catch (e){
      console.log(e);
      commit('unsetCustomerBalance');// reset all
      return false;
    }
  },
}