import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { RootState } from '~/store/index';
import AVS from '~/entities/AVS';

export const state = () => ({

})

export type ErrorsBagState = ReturnType<typeof state>

export const getters: GetterTree<ErrorsBagState, RootState> = {
}

export const mutations: MutationTree<ErrorsBagState> = {  
}

export const actions: ActionTree<ErrorsBagState, RootState> = {
  async createAddressRequest({commit}, data):Promise<AVS>{
    try{
        const addressRequest = await this.$axios.$post('/api/Address', data);
        return addressRequest.value;
    }catch (e){
      return Promise.reject(e);
    }
  },
  async editAddressRequest({commit}, data):Promise<AVS>{
    try{
        const addressRequest = await this.$axios.$put(`/api/Address/${data.addressId}`, data);
        return addressRequest.value;
    }catch (e){
      return Promise.reject(e);
    }
  }
}

