
import Vue from 'vue';
import Notice from "~/entities/Notice";
export default Vue.extend({
  name: 'Notices',
  data() {
    return {
      showing: this.show
    }
  },
  props: {
    notice: Notice,
    page: {
      type: [Number, String],
      default: 0
    },
    show: {
      type: Boolean,
      default: true
    },
    center: {
      type: Boolean,
      default: false
    },
    isHeader: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    hide() {
      this.showing = false
    },
    filterMessage() { //this function will make str.replaces using the replacements prop
      let message:string = this.notice?.message ? this.notice.message : '';
      if(this.notice?.replacements && Object.keys(this.notice.replacements).length > 0){
        for (const key in this.notice.replacements) {
          message = message.replace(key, this.notice.replacements[key]);
        }
      }
      return message;
    }
  },
});
