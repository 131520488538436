import Vue, { VNode } from 'vue';
import { DirectiveBinding } from 'vue/types/options';


Vue.directive('click-outside', {
    bind: function (el: any, binding: DirectiveBinding, vnode: VNode) {
        el.clickOutsideEvent = function (event: Event) {
            // here I check that click was outside the el and his childrens
            if (!(el == event.target || el.contains(event.target))) {
             
            // and if it did, call method provided in attribute value
                if(typeof binding.value==='function') {
                    binding.value.bind(vnode.context)(event)
                } 
                else {
                    if (!binding.expression)
                        return;
                    let expression = '';
                    let beg = ["'", '`', '"'].includes(binding.expression[0]) ? 1 : 0;
                    let count = ["'", '`', '"'].includes(binding.expression[binding.expression.length -1]) ? binding.expression.length -1 : binding.expression.length;
                    expression = binding.expression.substring(beg, count)
                    new Function(expression).bind(vnode.context)(event)
                }
            }
        };        
        document.body.addEventListener('click', el.clickOutsideEvent);
        document.body.addEventListener('touchstart', el.clickOutsideEvent)
    },
    unbind: function (el: any) {
        document.body.removeEventListener('click', el.clickOutsideEvent)        
        document.body.removeEventListener('touchstart', el.clickOutsideEvent)
    },
});